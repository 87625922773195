<template>
    <div class="layout layout-prod">
        <section class="layout-prod-head">
            <h3 class="layout-prod-title">最新产品</h3>
        </section>
        <section class="layout-prod-body">
            <div class="swiper-container">
                <swiper ref="mySwiper" class="banner" :options="swiperOptions">
                    <swiper-slide v-for="slide in [1, 2, 3, 4, 5]" :key="slide" class="slide-prod">
                        <div v-for="item in [1, 2, 3, 4]" :key="item" class="prod-item">
                            <a class="prod-item-link" href="#">
                                <img src="@a/img/product.png" alt="">
                                <span class="prod-item-name">『仙剑奇侠传』X『次元造物』12寸收藏级人偶 · 云天河云天河云天河云天河（琼华ver）</span>
                                <span class="prod-item-more">产品详情</span>
                            </a>
                            <span class="prod-item-date">
                                <b class="prod-item-date-txt">2020.02发售</b>
                            </span>
                        </div>
                    </swiper-slide>
                </swiper>
                <div class="swiper-scrollbar" />
            </div>
            <section class="prod-more">
                <a class="more-btn white" href="#">更多产品一览</a>
            </section>
        </section>
    </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
    name: 'LayoutProd',
    components: {
        Swiper,
        SwiperSlide
    },
    data() {
        return {
            swiperOptions: {
                scrollbar: {
                    el: '.swiper-scrollbar'
                },
                // autoplay: true, // 等同于以下设置
                loop: true
                // autoplay: {
                //     delay: 3000,
                //     stopOnLastSlide: false,
                //     disableOnInteraction: false
                // }
                // Some Swiper option/callback...
            }
        }
    },
    computed: {
        swiper() {
            return this.$refs.mySwiper.$swiper
        }
    },
    mounted() {
        // console.log('Current Swiper instance object', this.swiper)
        // this.swiper.slideTo(3, 1000, false)
    }
}
</script>
<style lang="scss" scoped>
    .layout.layout-prod {
        width: auto;
        margin-bottom: 0;
        background: $red;
    }
    .swiper-container {
        margin: 0 auto;
        width: 1200px;
    }
    .layout-prod-head {
        padding: 40px 0 0;
    }
    .layout-prod-title {
        padding: 20px 0 20px 20px;
        position: relative;
        text-align: center;
        font-size: 28px;
        color: $white;
    }
    .prod-more {
        padding: 20px 0 40px;
        text-align: center;
    }
    .slide-prod {
        padding-top: 120px;
    }
    .prod-item {
        position: relative;
        @include inlineBlock();
        @include widthHeight(285px, 375px);
        margin: 0 7px;
        padding: 10px;
        background-color: $white;
        border-radius: 10px;
    }
    .prod-item-link {
        display: block;
        height: 100%;
        text-align: center;
    }
    .prod-item-name {
        display: block;
        margin: 6px 0 12px;
        text-align: left;
        font-size: 14px;
        height: 40px;
        line-height: 1.4;
        @include textEllipsisMulltiple();
    }
    .prod-item-more {
        font-size: 14px;
        color: $red;
    }
    .prod-item-date {
        display: block;
        position: absolute;
        top: -120px;
        left: 50%;
        margin-left: -40px;
        width: 80px;
        height: 80px;
        padding: 10px;
        text-align: center;
        line-height: 1.5;
        font-size: 14px;
        border-radius: 50%;
        color: $red;
        background-color: $white;
        &::after {
            @include triangle('bottom', 10px, 14px, #fff);
            position: absolute;
            top: 90px;
            transform: translateX(-50%);
        }
    }
    .prod-item-date-txt {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
</style>
