
// @c an alias to /compontents
import pageHeader from '@c/pageHeader.vue'
import pageFooter from '@c/pageFooter.vue'
import homeBanner from './homeBanner.vue'
import layoutInfo from './layoutInfo.vue'
import layoutProd from './layoutProd.vue'
import layoutAbout from './layoutAbout.vue'
export default {
    name: 'Home',
    components: {
        pageHeader,
        pageFooter,
        homeBanner,
        layoutInfo,
        layoutProd,
        layoutAbout
    }
}
