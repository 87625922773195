<template>
    <div class="layout layout-info">
        <section class="info-head">
            <h3 class="info-title">资讯</h3>
        </section>
        <section class="info-body">
            <info-list :info-list="infoList" />
            <section class="info-more">
                <a class="more-btn" href="#">更多资讯一览</a>
            </section>
        </section>
    </div>
</template>
<script>
import infoList from '@c/infoList.vue'
export default {
    name: 'LayoutInfo',
    components: {
        infoList
    },
    data() {
        return {
            infoList: [1, 2, 3, 4]
        }
    }
}
</script>
<style lang="scss" scoped>
    .layout-info {
        padding-top: 20px;
        width: auto;
        background-color: $white;
    }
    .info-head {
        margin: 0 auto 20px;
        width: 1200px;
        border-bottom: 1px solid #e5e9f2;
    }
    .info-body {
        width: 1200px;
        margin: 0 auto;
    }
    .info-title {
        padding: 20px 0 20px 20px;
        position: relative;
        font-size: 18px;
        &::before {
            content: ' ';
            position: absolute;
            height: 24px;
            width: 2px;
            left: 0;
            top: 18px;
            background-color: $red;
        }
    }
    .info-item {
        display: inline-block;
        vertical-align: top;
        width: 285px;
        & + .info-item {
            margin-left: 20px;
        }
    }
    .info-img {
        display: block;
    }
    .info-name {
        padding: 10px;
        display: block;
        height: 35px;
        font-size: 14px;
    }
    .info-price {
        border-bottom: 1px solid $gray;
        padding-bottom: 10px;
    }
    .info-price-item {
        padding: 6px 10px;
        display: block;
        color: $light;
    }
    .info-series {
        padding: 16px 10px;
        text-align: justify;
    }
    .info-series-date {
        float: right;
        .icon {
            margin-top: -1px;
        }
    }
    .info-more {
        padding: 20px 20px 40px;
        text-align: center;
    }
</style>
