<template>
    <swiper ref="mySwiper" class="banner" :options="swiperOptions">
        <swiper-slide>
            <a href="#" class="swiper-link">
                <img src="@a/img/banner.png">
            </a>
        </swiper-slide>
        <swiper-slide>
            <a href="#" class="swiper-link">
                <img src="@a/img/banner.png">
            </a>
        </swiper-slide>
        <swiper-slide>
            <a href="#" class="swiper-link">
                <img src="@a/img/banner.png">
            </a>
        </swiper-slide>
        <swiper-slide>
            <a href="#" class="swiper-link">
                <img src="@a/img/banner.png">
            </a>
        </swiper-slide>
        <swiper-slide>
            <a href="#" class="swiper-link">
                <img src="@a/img/banner.png">
            </a>
        </swiper-slide>
        <div slot="pagination" class="swiper-pagination" />
    </swiper>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
    name: 'HomeBanner',
    components: {
        Swiper,
        SwiperSlide
    },
    data() {
        return {
            swiperOptions: {
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                // autoplay: true, // 等同于以下设置
                loop: true,
                autoplay: {
                    delay: 3000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false
                }
                // Some Swiper option/callback...
            }
        }
    },
    computed: {
        swiper() {
            return this.$refs.mySwiper.$swiper
        }
    },
    mounted() {
        // console.log('Current Swiper instance object', this.swiper)
        // this.swiper.slideTo(3, 1000, false)
    }
}
</script>
<style lang="scss" scoped>
.banner {
    height: 600px;
}
.swiper-link {
    display: block;
    height: 100%;
    text-align: center;
    background-color: #eceff8;
}
</style>
